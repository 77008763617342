/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Dang Nhat Huy",
  title: "Hi all, I'm Huy",
  subTitle: emoji(
    "A senior student, studying for a bachelor’s degree in Information Technology, majoring in Computer Science 👨‍💻 He is passionate about system DevOps development and back-end development using Java. Also, he is willing to do his best on other cloud computing, project operations, and working on servers."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1vdTIYv0vu6598oNa7L93XZCy-Jdqtos1/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/Nhathuy1305",
  linkedin: "https://www.linkedin.com/in/nhathuy1305/",
  gmail: "dangnhathuy.work@gmail.com",
  gitlab: "https://gitlab.com/Nhathuy1305",
  twitter: "https://twitter.com/Nhathuy1305",
  display: true 
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "CRAZY DEVOPS ENGINEER WHO WANTS TO EXPLORE EVERY TECH STACK",
  skills: [
    emoji(
      "⚡ Design and maintain continuous integration and continuous deployment pipelines using tools like Jenkins, GitLab CI, or Github Actions"
    ),
    emoji("⚡ Deploy, scale, and manage containerized applications using Kubernetes for efficient orchestration"),
    emoji(
      "⚡ Integrate cloud services from AWS, Azure, or Google Cloud Platform to leverage scalable and cost-effective solutions"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "javascript",
      fontAwesomeClassname: "fab fa-js"
    },
    // {
    //   skillName: "spring",
    //   fontAwesomeClassname: "fab fa-spring"
    // },
    // {
    //   skillName: "reactjs",
    //   fontAwesomeClassname: "fab fa-react"
    // },
    {
      skillName: "jenkins",
      fontAwesomeClassname: "fab fa-jenkins"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "kubernetes",
      fontAwesomeClassname: "fas fa-dharmachakra"
    },
    {
      skillName: "argocd",
      fontAwesomeClassname: "fab fa-octopus-deploy"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "azure",
      fontAwesomeClassname: "fab fa-microsoft"
    },
    {
      skillName: "google cloud",
      fontAwesomeClassname: "fas fa-cloud"
    },
    {
      skillName: "prometheus",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "grafana",
      fontAwesomeClassname: "far fa-sun"
    },
    {
      skillName: "terraform",
      fontAwesomeClassname: "fas fa-project-diagram"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "linux",
      fontAwesomeClassname: "fab fa-linux"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "International University - Vietnam National University",
      logo: require("./assets/images/iuLogo.png"),
      subHeader: "Bachelor of Science in Computer Science",
      duration: "September 2020 - Present",
      desc: "Studied core computer science principles, specializing in software engineering, database management, and artificial intelligence. Completed challenging projects including a secure e-commerce platform and a sentiment analysis model. Achieved recognition on the Dean's List for academic excellence. Eager to apply skills and knowledge to real-world challenges.",
      descBullets: [
        "Operating Systems; Databases; Algorithms & Data Structures; Programming Languages; Computer Architecture; Object-Oriented Analysis and Design",
        "Entrepreneurship; Engineering Ethics; Calculus; Physics"
      ]
    },
    // {
    //   schoolName: "Stanford University",
    //   logo: require("./assets/images/stanfordLogo.png"),
    //   subHeader: "Bachelor of Science in Computer Science",
    //   duration: "September 2013 - April 2017",
    //   desc: "Ranked top 10% in the program. Took courses about Software Engineering, Web Security, Operating Systems, ...",
    //   descBullets: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit"]
    // }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "System Design", //Insert stack or technology you have experience in
      progressPercentage: "80%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "70%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "DevOps Engineer Intern",
      company: "Viettel High Tech",
      companylogo: require("./assets/images/viettelLogo.png"),
      date: "Mar 2024 – Present",
      // desc: "Implemented counselors recruiter services within the microservice website using Java and Spring Boot Framework, executed front-end solutions using ReactJS.",
      // descBullets: [
      //   "Demonstrated expertise in creating RESTful APIs to efficiently collect data from online e-commerce items and convert them into JSON files for seamless transmission to company vendors.",
      //   "Collaborated effectively with a team using Azure DevOps for version control and CI/CD development, ensuring streamlined project delivery and reducing ad hoc work requests by 30%.",
      //   "Communicated with the Developer team using Agile methodologies, particularly the Kanban framework, through Jira Software, and collaborated effectively with the Business Analysis team."
      // ]
    },
    {
      role: "Full-stack Developer Intern",
      company: "FWD VietNam",
      companylogo: require("./assets/images/fwdLogo.png"),
      date: "May 2023 – Aug 2023",
      desc: "Implemented counselors recruiter services within the microservice website using Java and Spring Boot Framework, executed front-end solutions using ReactJS.",
      descBullets: [
        "Demonstrated expertise in creating RESTful APIs to efficiently collect data from online e-commerce items and convert them into JSON files for seamless transmission to company vendors.",
        "Collaborated effectively with a team using Azure DevOps for version control and CI/CD development, ensuring streamlined project delivery and reducing ad hoc work requests by 30%.",
        "Communicated with the Developer team using Agile methodologies, particularly the Kanban framework, through Jira Software, and collaborated effectively with the Business Analysis team."
      ]
    },
    // {
    //   role: "Front-End Developer",
    //   company: "Quora",
    //   companylogo: require("./assets/images/quoraLogo.png"),
    //   date: "May 2017 – May 2018",
    //   desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    // },
    // {
    //   role: "Software Engineer Intern",
    //   company: "Airbnb",
    //   companylogo: require("./assets/images/airbnbLogo.png"),
    //   date: "Jan 2015 – Sep 2015",
    //   desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    // }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME MY TEAMWORK IDEAS THAT I IMPLEMENTED TO CREATE DIFFERENCES",
  projects: [
    {
      image: require("./assets/images/betterdayLogo.png"),
      projectName: "BETTERday Tool",
      projectDesc: "The advanced Video Retrieval Tool developed for the competition in AI Challenge 2023 - Ho Chi Minh City",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://en.betterday.tv/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/battleshipLogo.png"),
      projectName: "Battleship",
      projectDesc: "The game is built for Data Structure and Algorithm course",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://battleship-remastered.vercel.app/"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Google IT Automation with Python Specialization",
      subtitle:
        "Operating System, Cloud Management, Git, Automating Real-World Tasks",
      image: require("./assets/images/googleLogo.webp"),
      imageAlt: "Google Code-In Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://www.coursera.org/account/accomplishments/specialization/certificate/A2DES9QALEEL"
        },
        // {
        //   name: "Award Letter",
        //   url: "https://drive.google.com/file/d/0B7kazrtMwm5dekxBTW5hQkg2WXUyR3QzQmR0VERiLXlGRVdF/view?usp=sharing"
        // },
        // {
        //   name: "Google Code-in Blog",
        //   url: "https://opensource.googleblog.com/2019/01/google-code-in-2018-winners.html"
        // }
      ]
    },
    {
      title: "Google IT Support Specialization",
      subtitle:
        "Computer Networking, System Administration, IT Infrastructure Services, IT Security",
      image: require("./assets/images/googleLogo.webp"),
      imageAlt: "Google Assistant Action Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://www.coursera.org/account/accomplishments/specialization/certificate/SQ8NP59EWKQF"
        }
      ]
    },
    {
      title: "Google Project Management Specialization",
      subtitle: "Project Planning, Project Execution, Agile Management, Capstone",
      image: require("./assets/images/googleLogo.webp"),
      imageAlt: "PWA Logo",
      footerLink: [
        {name: "Certification",
         url: "https://www.coursera.org/account/accomplishments/specialization/certificate/75AG79SPHP58"
        }
      ]
    },
    {
      title: "LambdaTest Software Testing Professional Certificate",
      subtitle: "Software Testing, Agile Testing",
      image: require("./assets/images/lambdatestLogo.avif"),
      imageAlt: "PWA Logo",
      footerLink: [
        {name: "Certification",
          url: "https://www.linkedin.com/learning/certificates/bf87a6bc9029a94926ec059cfbf78597c2b9230ba2b403aef1a11bf55c735e62?accountId=86700338&u=86700338&success=true&authUUID=7jr66YgyTGq6xXS0bfQrjg%3D%3D"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and share others what I have learnt.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://www.linkedin.com/posts/nhathuy1305_aws-ec2-activity-7074406774518263808-EgCo?utm_source=share&utm_medium=member_desktop",
      title: "A few share my story of reducing AWS EC2 costs by 40%.",
      description:
        "The problem is that I want to host a website to share DevOps knowledge for free with the community at vntechies.dev."
    },
    {
      url: "https://www.linkedin.com/posts/nhathuy1305_k8s-kubectl-activity-7105580188066881536-oXqr?utm_source=share&utm_medium=member_desktop",
      title: "Some of my views after participating in setting up a multinode Kubernetes cluster with Vagrant",
      description:
        "I am using Linux so I will use Bash to execute workstation commands with vagrant."
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+92-0000000000",
  email_address: "saadpasta70@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "Nhathuy1305",
  display: true
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
